














import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Car } from '@/store/types';
import { CheckoutStepOneFormMixin } from '@/components/screens/checkout/CheckoutStepOneForm.mixin';
import UserAccount from '@/components/user/userAccount.vue';
import SummaryBox from '@/components/checkout/summary/SummaryBox.vue';
import Help from '@/components/checkout/Help.vue';
import { Route } from 'vue-router';

@Component({
  components: {
    UserAccount,
    SummaryBox,
    Help
  },
  mixins: [CheckoutStepOneFormMixin]
})
export default class CheckoutStepOne extends Mixins(CheckoutStepOneFormMixin) {
  @Prop() readonly car: Car | undefined;
  @Prop() readonly from!: Route;
  private isUserProfile = false;

  mounted() {
    this.$store.dispatch('setActiveDiscount', null);
  }

  get queryFrom(): string {
    return this.$route.query.from.toString() ?? '';
  }

  get queryTo(): string {
    return this.$route.query.to.toString() ?? '';
  }

  private changeActiveStep(step: number) {
    this.$emit('changeActiveStep', step);
  }
}
